

// -------------------
// pdf
// -------------------
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



const QrPreviewPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  //`http://192.168.6.229:11011/nradmin/qr/showCert`,
  // `${process.env.PUBLIC_API_URL}/qr/showCert`,
  // ttp://192.168.6.229:11011/nradmin
//          { issueNo: issue_no, str: 'ad0f613b365f5b60ee3edb5a7ac32dad3271367131ccdd5b2958188329a45e29' },  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const issue_no = params.get("issue_no") || null;
        const str = params.get("str") || null;
        const response = await axios.post(
          `https://nradmin.number-ro.com/nradmin/qr/showCert`,
          { issueNo: issue_no, str: str },
          { responseType: "arraybuffer" }
        );

        if (!response.data || response.data.byteLength === 0) {
          setError("Data has expired or is not available.");
          return;
        }

        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);

      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.search]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div>
        {/* desktop */}
        <div className="content-container mypage_wrapper d-md-block d-none">
          <div
            className="container pb-md-0 pb-5 mb-md-0 mb-5 top-border"
            style={{
              // height: "650px",
              overflow: "scroll",
            }}
          >
            <div className="d-flex justify-content-center mb-5">

              {pdfUrl ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfUrl}
                    // plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              ) : (
                <p>Loading PDF...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="container d-md-none d-block mt-5">
        <div className="report-section">
          {/* <div
              style={{
                background: "#e4e4e4",
                display: "flex",
                justifyContent: "center",
                minHeight: "300px",
                height: "auto",
                width: "50%",
                marginBottom: "20px",
                overflow: "auto",
              }}
            > */}
          {pdfUrl ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfUrl}
                // plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          ) : (
            <p>Loading PDF...</p>
          )}
        </div>
        <div className="d-flex justify-content-center report-m-button">
          
        </div>
      </div>

    </>
  );
}

 export default QrPreviewPage;
//-------------------------
// 이미지
//-------------------------
// import React, { useEffect, useState } from "react";

// import axios from 'axios';
// import { Container} from "react-bootstrap";
// import { useLocation } from 'react-router-dom';


// const QrPreviewPage = () => {

//   const location = useLocation();
//   const [imageSrc, setImageSrc] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
// //          `${process.env.REACT_APP_DB_HOST}/qr/showCert`,
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const params = new URLSearchParams(location.search);
//         const issue_no = params.get('issue_no') || null;
//         const str =params.get('str') || null;
//         const response = await axios.post(
//           `http://192.168.6.229:11011/nradmin/qr/showCert`,
//           { issueNo: issue_no,
//             str: str
//            },
//           { responseType: 'arraybuffer' } // Important: Tell Axios to expect an arraybuffer response
//         );
//         if (!response.data || response.data.byteLength === 0) {
//           setError('Data has expired or is not available.');
//           return;
//         }
//         // Convert arraybuffer to base64 string
//         const base64String = btoa(
//           new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
//         );

//         // Construct data URL
//         const imageDataUrl = `data:image/png;base64,${base64String}`;

//         setImageSrc(imageDataUrl); // Set the image source
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError(error.message || 'An error occurred while fetching data.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [location.search]);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div> {error}</div>;

//   return (

//       <Container sx={{ padding: '48px 16px' }}>
//           {imageSrc && <img src={imageSrc} alt="Certificate Preview" />} {/* 이미지가 준비되면 보여줌 */}
//       </Container>

//   );
// };

//  export default QrPreviewPage;