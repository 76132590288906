import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import * as R from "../../../css/PersonalSellingRequest/requestForm";
import CardButton from "../../../componant/buttons/cardButton";
import { headerText } from "../../../App";
import { useAtom } from "jotai";
import { postNumbaCodeList, postNumbaSelUserInfo } from "../../../api/auth";
import { toast } from "wc-toast";
import {
  postNumbaInsSellCorp,
  postNumbaInsSellIndi,
  postNumbaInsSellLic,
} from "../../../api/sell";
import { DaumPostcode } from "../../../utils/DaumPostCode";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../utils/hooks/scrollToTop";
import axios from "axios";
import config from "../../../config";

const SellingFormDetails = ({ activeTab, setActiveTab }) => {
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const initialSellReq = {
    fbl_file: null,
    car_file: null,
    car_files: [],
    deal_category_cd: activeTab === 1 ? "2101" : activeTab === 3 ? "2104" : "",
    region_cd: "",
    user_nm: "",
    phone: "",
    zip: "",
    addr1: "",
    addr2: "",
    req_comment: "",
    car_weight_cd: "",
    car_weight: "",
    car_yyyymm: "",
    price: "",
    brno_file: null,
    brno_auth_flag: "",
    corp_nm: "",
    whole_yn: "",
    nambaList: [],
    fabl_file: null,
    tax_paper_yn: "",
    cp_cnt: 0,
    monthly_amount: "",
    quick_rate: "",
    freight_rate: "",
    cp_price: "",
    in_car_yn: "N",
    ecar_yn: "",
  };
  const [sellReq, setSellReq] = useState(initialSellReq);
  const [regionCdList, setRegionCdList] = useState([]);
  const [corpCarCdList, setCorpCarCdList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSellChecked, setIsSellChecked] = useState(false);
  const [sellCnt, setSellCnt] = useState(0);
  const [corpNamba, setCorpNamba] = useState({
    car_yyyymm: "",
    car_weight_cd: "",
    price: "",
    ji_price: "",
    jigong_flag: "",
    in_car_yn: "N",
  });
  const [nambaList, setNambaList] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isCarChecked, setIsCarChecked] = useState(false); // 개인넘버 차량포함
  const [isCarsChecked, setIsCarsChecked] = useState([]); // 법인넘버 차량포함
  const [priceData, setPriceData] = useState([]);
  const [newPrice, setNewPrice] = useState(0);

  const now = new Date();
  let years = [];
  for (let y = now.getFullYear(); y >= 1995; y -= 1) {
    years.push(y);
  }
  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push("0" + m.toString());
    } else {
      month.push(m.toString());
    }
  }

  // 시세가격 가져오기
  const callGetPrice = async () => {
    // const data = await getPrice();
    // setPriceData(data);

    await axios({
      url: config.API_URL + "/comm/selMvalue",
      method: "post",
      data: {},
      headers: {
        ...axios.defaults.headers,
        Authorization: "",
      },
    }).then((res) => {
      setPriceData(res.data.resultList);
      if (parseInt(sellReq.deal_category_cd) === 2104) {
        const foundData = res.data.resultList.find(data => data.deal_category_cd === 2104);
        if (foundData) {
          setNewPrice(foundData.avg_price);
          console.log('---', foundData.avg_price);
        }
      }
    }).catch((error)=>{
      console.log("시세가격 가져오기 실패");
      return 0;    
    });   
  }

  const getRegionCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 1000 });
      if (response.code !== 1000) {
        console.log("지역코드 가져오기 실패");
        return;
      }
      setRegionCdList(response.resultList[0]);
    } catch (err) {
      console.log("지역코드 가져오기 실패");
    }
  };

  const getCorpCarCd = async () => {
    try {
      const response = await postNumbaCodeList({ pcd_seq: 2400 });
      if (response.code !== 1000) {
        console.log("법인 톤수 가져오기 실패");
        return;
      }
      setCorpCarCdList(response.resultList[0]);
    } catch (err) {
      console.log("법인 톤수 가져오기 실패");
    }
  };

  useEffect(() => {
    setHeaderTextAtom("팝니다 등록하기");
    getRegionCd();
    getUserInfo();
    scrollToTop();
    callGetPrice();
    console.log("-------------------", activeTab);
  }, []);

  useEffect(() => {
    setSellReq(initialSellReq);
    setSellCnt(0);
    setIsSellChecked(false);
    setIsCarChecked(false);
    setIsChecked(true);
    getUserInfo();
    callGetPrice();

    if (activeTab === 2) {
      getCorpCarCd();
    }
  }, [activeTab]);

  useEffect(() => {
    const updatedNambaList = [];
    for (let i = 0; i < sellCnt; i++) {
      updatedNambaList.push({ ...corpNamba });
    }
    setNambaList(updatedNambaList);
  }, [sellCnt]);

  useEffect(() => {
    setSellReq((prevState) => ({
      ...prevState,
      nambaList: nambaList,
    }));
  }, [nambaList]);

  useEffect(() => {
    if (
      parseInt(sellReq.deal_category_cd) === 2102 ||
      parseInt(sellReq.deal_category_cd) === 2103
    ) {
      setSellReq((prevState) => ({
        ...prevState,
        nambaList: [],
      }));
      setSellCnt(0);
    }
  }, [sellReq.deal_category_cd]);

  const handleInputChange = (event) => {
    console.log("sellReq4", sellReq);
    const { name, value } = event.target;
    if (name === "car_weight_cd") {
      const foundData = priceData.find(
        (data) =>
          data.deal_category_cd === 2101 &&
          data.car_weight_cd === parseInt(value)
      );
      if (foundData) {
        setNewPrice(foundData.avg_price);
        console.log("---", foundData.avg_price);
      }
    }
    // if (name === "deal_category_cd" && value === "2104"){
    //   const foundData = priceData.find(data => data.deal_category_cd === 2104);
    //   if (foundData) {
    //     setNewPrice(foundData.avg_price);
    //     console.log('---', foundData.avg_price);
    //   }
    // }

    const yyyymm =
      document.getElementById("yyyy")?.value +
      document.getElementById("mm")?.value;

    if (name === "car_weight") {
      setSellReq((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9.]/g, ""),
        car_yyyymm: yyyymm,
      }));
    } else if (name === "price") {
      setSellReq((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: yyyymm,
      }));
    } else if (
      name === "cp_cnt" ||
      name === "monthly_amount" ||
      name === "quick_rate" ||
      name === "freight_rate" ||
      name === "cp_price"
    ) {
      setSellReq((prevProps) => ({
        ...prevProps,
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: yyyymm,
      }));
    } else {
      setSellReq((prevProps) => ({
        ...prevProps,
        [name]: value,
        car_yyyymm: yyyymm,
      }));
    }
  };

  const handleFblFileSelect = (event) => {
    const file = event.target.files[0];
    setSellReq((prevState) => ({
      ...prevState,
      fbl_file: file,
    }));
  };
  const renderFblFileInfo = () => {
    if (!sellReq.fbl_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {sellReq.fbl_file.name}
      </R.Btn_Value>
    );
  };

  const handleFablFileSelect = (event) => {
    const file = event.target.files[0];
    setSellReq((prevState) => ({
      ...prevState,
      fabl_file: file,
    }));
  };
  const renderFablFileInfo = () => {
    if (!sellReq.fabl_file) {
      return (
        // <R.Btn_Value className="d-none d-lg-inline-block">
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {sellReq.fabl_file.name}
      </R.Btn_Value>
    );
  };

  const handleBrnoFileSelect = (event) => {
    const file = event.target.files[0];
    setSellReq((prevState) => ({
      ...prevState,
      brno_file: file,
    }));
  };
  const renderBrnoFileInfo = () => {
    if (!sellReq.brno_file) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {sellReq.brno_file.name}
      </R.Btn_Value>
    );
  };

  const handleCarFileSelect = (event) => {
    const file = event.target.files[0];
    setSellReq((prevState) => ({
      ...prevState,
      car_file: file,
    }));
  };
  const renderCarFileInfo = () => {
    if (!sellReq.car_file) {
      return (
        // <R.Btn_Value className="d-none d-lg-inline-block">
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {sellReq.car_file.name}
      </R.Btn_Value>
    );
  };

  const handleCarFilesSelect = (event, index) => {
    const file = event.target.files[0];
    const updatedCarFiles = [...sellReq.car_files];
    updatedCarFiles[index] = file;
    setSellReq((prevState) => ({
      ...prevState,
      car_files: updatedCarFiles,
    }));
  };

  const renderCarFilesInfo = (index) => {
    if (!sellReq.car_files[index]) {
      return (
        <R.Btn_Value className="d-lg-inline-block">
          선택된 파일이 없음.
        </R.Btn_Value>
      );
    }
    return (
      <R.Btn_Value className="d-lg-inline-block">
        {sellReq.car_files[index].name}
      </R.Btn_Value>
    );
  };

  async function getUserInfo() {
    try {
      const response = await postNumbaSelUserInfo({});
      if (response.code === 1000) {
        setUserInfo(response);
        setSellReq({
          ...sellReq,
          brno_auth_flag: response.brno_auth_flag,
          corp_nm: response.corp_nm,
          user_nm: response.user_nm,
          phone: response.phone,
          zip: response.zip,
          addr1: response.addr1,
          addr2: response.addr2,
        });
      } else {
      }
    } catch (err) {}
  }

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      getUserInfo();
    } else {
      setSellReq({
        ...sellReq,
        brno_auth_flag: "",
        corp_nm: "",
        user_nm: "",
        phone: "",
        zip: "",
        addr1: "",
        addr2: "",
      });
    }
  };

  const handleCheckboxChange2 = async () => {
    setIsSellChecked(!isSellChecked);
    if (isSellChecked) {
      setSellReq({
        ...sellReq,
        cp_cnt: 0,
        monthly_amount: "",
        quick_rate: "",
        freight_rate: "",
        cp_price: "",
      });
    }
  };

  const handleCheckboxChange3 = async () => {
    setIsCarChecked(!isCarChecked);
    if (!isCarChecked) {
      setSellReq({
        ...sellReq,
        in_car_yn: "Y",
      });
    } else {
      setSellReq({
        ...sellReq,
        in_car_yn: "N",
      });
    }
  };

  const validateSellReq = (activeTab, sellReq) => {
    // console.log(sellReq?.brno_auth_flag, sellReq?.corp_nm);
    if (userInfo?.user_type_cd === 202 && !sellReq?.corp_nm) {
      return "법인명을 확인해주세요.";
    }
    if (activeTab === 1) {
      if (!sellReq.car_weight_cd || sellReq.car_weight_cd === "") {
        return "넘버 구분을 선택하세요.";
      }
      if (!sellReq.car_weight || !/^[0-9.]+$/.test(sellReq.car_weight)) {
        return "차량 톤수를 확인하세요.";
      }
      if (parseInt(sellReq.car_weight_cd) === 2301) {
        if (parseFloat(sellReq.car_weight) > 1.5) {
          return "차량 톤수를 확인하세요.";
        }
        if (!sellReq.ecar_yn || sellReq.ecar_yn === "") {
          return "차량구분을 선택하세요.";
        }
      } else if (parseInt(sellReq.car_weight_cd) === 2302) {
        if (
          parseFloat(sellReq.car_weight) <= 1.5 ||
          parseFloat(sellReq.car_weight) > 16
        ) {
          return "차량 톤수를 확인하세요.";
        }
      } else {
        if (parseFloat(sellReq.car_weight) <= 16) {
          return "차량 톤수를 확인하세요.";
        }
      }
      if (
        parseFloat(sellReq.car_weight) <= 0 ||
        parseFloat(sellReq.car_weight) > 100
      ) {
        return "차량 톤수를 확인하세요.";
      }
      if (!sellReq.car_yyyymm || sellReq.car_yyyymm === "") {
        return "차량 연식을 입력하세요.";
      }
      if (!/^(19|20)\d{2}(0[1-9]|1[0-2])$/.test(sellReq.car_yyyymm)) {
        return "차량연식을 확인하세요.";
      }
      // 선택으로 돌리기
      // if (!sellReq.car_file) {
      //   return "자동차등록증을 확인해 주세요.";
      // }
      // if (!sellReq.fbl_file) {
      //   return "화물자동차운송사업허가증을 확인해 주세요.";
      // }
    }

    if (activeTab === 2) {
      if (sellReq.nambaList.length < 1) {
        return "판매대수를 확인해 주세요.";
      }
      if (sellReq.deal_category_cd === "2102") {
        if (!sellReq.whole_yn || sellReq.whole_yn === "") {
          return "판매방법을 선택하세요.";
        }
        let cnt = 0;
        let carFileCnt = 0;
        for (const carFile of sellReq.car_files) {
          if (carFile !== null && carFile !== undefined) {
            carFileCnt += 1;
          }
        }
        for (const namba of sellReq.nambaList) {          
          if (!namba.jigong_flag || namba.jigong_flag === "") {
            return "넘버종류를 선택하세요.";
          }
          if (!namba.car_weight_cd || namba.car_weight_cd === "") {
            return "톤수를 선택하세요.";
          }
          if (namba.jigong_flag === "J") {
            if (!namba.car_yyyymm || namba.car_yyyymm === "") {
              return "차량연식을 입력하세요.";
            }
          }
          if (
            !namba.price ||
            namba.price === "" ||
            parseInt(namba.price) <= 0
          ) {
            return "판매금액을 입력하세요.";
          }
          if (namba.price % 10 !== 0) {
            return "판매금액을 확인해 주세요(10만원 단위)";
          }
          cnt += 1;
        }
        // 선택사항
        // if (cnt !== carFileCnt) {
        //   return "자동차등록증을 확인해 주세요.";
        // }
      }
      if (sellReq.deal_category_cd === "2103") {
        let cnt = 0;
        let carFileCnt = 0;
        for (const carFile of sellReq.car_files) {
          if (carFile !== null && carFile !== undefined) {
            carFileCnt += 1;
          }
        }
        for (const namba of sellReq.nambaList) {
          if (!namba.car_weight_cd || namba.car_weight_cd === "") {
            return "톤수를 선택하세요.";
          }
          if (!namba.car_yyyymm || namba.car_yyyymm === "") {
            return "차량연식을 입력하세요.";
          }
          if (!/^(19|20)\d{2}(0[1-9]|1[0-2])$/.test(namba.car_yyyymm)) {
            return "차량연식을 확인하세요.";
          }
          if (namba.price % 10 !== 0) {
            return "판매금액을 확인해 주세요(10만원 단위)";
          }
          if (
            !namba.price ||
            namba.price === "" ||
            parseInt(namba.price) <= 0
          ) {
            return "판매금액을 입력하세요.";
          }
          if (
            !namba.ji_price ||
            namba.ji_price === "" ||
            parseInt(namba.ji_price) <= 0
          ) {
            return "지입료를 입력하세요.";
          }
          cnt += 1;
        }
        // 선택사항
        // if (cnt !== carFileCnt) {
        //   return "자동차등록증을 확인해 주세요.";
        // }
      }
      // 선택사항
      // if (!sellReq.brno_file) {
      //   return "사업자등록증을 입력하세요.";
      // }
    }

    if (activeTab === 3) {
      // 개인회원 선택, 법인필수
      // 선택사항
      // if (userInfo?.user_type_cd === 202 && !sellReq.brno_file) {
      //   return "사업자등록증을 확인해 주세요.";
      // }
      // if (!sellReq.fabl_file) {
      //   return "화물자동차운송 주선사업허가증을 확인해 주세요.";
      // }
      if (!sellReq.tax_paper_yn || sellReq.tax_paper_yn === "") {
        return "세금계산서를 확인해 주세요.";
      }
      if (parseInt(sellReq.cp_cnt) < 0) {
        return "거래처 수를 확인해 주세요.";
      }
      if (sellReq.cp_cnt > 0) {
        if (
          !sellReq.monthly_amount ||
          sellReq.monthly_amount === "" ||
          parseInt(sellReq.monthly_amount) <= 0
        ) {
          return "평균 월 매출을 확인해 주세요.";
        }
        if (!sellReq.quick_rate || sellReq.quick_rate === "") {
          return "퀵 비율을 확인해 주세요.";
        }
        if (!sellReq.freight_rate || sellReq.freight_rate === "") {
          return "화물 비율을 확인해 주세요.";
        }
        if (
          parseInt(sellReq.quick_rate) + parseInt(sellReq.freight_rate) !==
          100
        ) {
          return "퀵, 화물 비율을 확인해 주세요.";
        }
        if (
          !sellReq.cp_price ||
          sellReq.cp_price === "" ||
          parseInt(sellReq.cp_price) <= 0
        ) {
          return "희망 거래가격을 확인해 주세요.";
        }
      }
    }

    // 선택사항
    // if (activeTab === 1 || activeTab === 2) {
    //   if (!sellReq.fbl_file) {
    //     return "화물자동차운송사업허가증을 확인해 주세요.";
    //   }
    // }

    if (activeTab === 1 || activeTab === 3) {
      if (
        !sellReq.price ||
        sellReq.price === "" ||
        parseInt(sellReq.price) <= 0
      ) {
        return "판매금액을 입력하세요.";
      }
      if (sellReq.price % 10 !== 0) {
        return "판매금액을 확인해 주세요(10만원 단위)";
      }
      if(!isCarChecked){
        if (
          parseInt(sellReq.price) < newPrice - 400 ||
          parseInt(sellReq.price) > newPrice + 400
        ) {
          return "판매금액을 확인해 주세요 (시세의 상하 400만원 범위)";
        }
      }
    }

    if (!sellReq.region_cd || sellReq.region_cd === "") {
      return "판매자 지역을 선택하세요.";
    }

    if (!sellReq.deal_category_cd || sellReq.deal_category_cd === "") {
      return "매물 종류를 선택하세요.";
    }

    if (!sellReq.user_nm || sellReq.user_nm === "" || sellReq.user_nm === undefined) {
      return "판매자 이름을 입력하세요.";
    }

    if (!sellReq.phone || sellReq.phone === "" || sellReq.phone === undefined) {
      return "판매자 휴대폰 번호를 입력하세요.";
    }

    if (!sellReq.zip || sellReq.zip === "" || sellReq.zip === undefined) {
      return "판매자 우편번호를 입력하세요.";
    }

    if (!sellReq.addr1 || sellReq.addr1 === "" || sellReq.addr1 === undefined) {
      return "판매자 도로명 주소를 입력하세요.";
    }

    if (!sellReq.addr2 || sellReq.addr2 === "" || sellReq.addr2 === undefined) {
      return "판매자 상세 주소를 입력하세요.";
    }
  };

  const handleSubmit = async () => {
    if (isClicked) {
      return;
    }
    setIsClicked(true);
    if (activeTab === 1 && userInfo.user_type_cd !== 201) {
      setIsClicked(false);
      return toast.error("개인회원만 등록할 수 있습니다.");
    }
    // if (activeTab === 2 && userInfo.user_type_cd !== 202) {
    //   setIsClicked(false);
    //   return toast.error("법인회원만 등록할 수 있습니다.");
    // }
    const msg = validateSellReq(activeTab, sellReq);
    if (msg) {
      setIsClicked(false);
      return toast.error(msg);
    }

    try {
      let response = null;
      if (activeTab === 1) {
        // console.log(sellReq);
        response = await postNumbaInsSellIndi(sellReq);
      }
      if (activeTab === 2) {
        const modify = {
          ...sellReq,
          nambaList: JSON.stringify(nambaList),
        };
        // console.log('법인매매/임대 데이터', modify);
        response = await postNumbaInsSellCorp(modify);
      }
      if (activeTab === 3) {
        // console.log(sellReq);
        response = await postNumbaInsSellLic(sellReq);
      }
      if (response.code !== 1000) {
        setIsClicked(false);
        toast.error("등록 실패");
        return;
      }
      toast.success("등록되었습니다.");
      navigate("/selling");
    } catch (e) {
      setIsClicked(false);
      toast.error("등록 서버 오류");
    }
  };

  const setCntChange = () => {
    if (
      sellReq.deal_category_cd !== "2102" &&
      sellReq.deal_category_cd !== "2103"
    ) {
      toast.error("매매/임대를 선택해 주세요");
      return;
    }
    const inputValue = document.getElementById("sellCnt").value;
    const value = parseInt(inputValue);
    if (inputValue === "" || value < 1) {
      toast.error("판매대수를 확인해 주세요");
      return;
    }
    setSellCnt(value);
    setIsCarsChecked(Array(value).fill(false)); // 처량포함체크
    setSellReq((prevState) => ({
      ...prevState,
      car_files: [],
    }));
  };

  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;

    const updatedNambaList = [...nambaList];

    const yyyymm =
      document.getElementById(`yyyy${index}`)?.value +
      document.getElementById(`mm${index}`)?.value;

    if (name.startsWith("jigong_flag")) {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        jigong_flag: value,
      };
      setNambaList(updatedNambaList);
    } else if (name === "price") {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: yyyymm,
      };
      setNambaList(updatedNambaList);
    } else if (name === "ji_price") {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        [name]: value.replace(/[^0-9]/g, ""),
        car_yyyymm: yyyymm,
      };
      setNambaList(updatedNambaList);
    } else if (name === "in_car_yn") {
      const isChecked = e.target.checked;
      const updatedChecked = [...isCarsChecked];
      updatedChecked[index] = isChecked;
      setIsCarsChecked(updatedChecked);
      console.log(isCarsChecked);

      if (!isCarsChecked[index]) {
        updatedNambaList[index] = {
          ...updatedNambaList[index],
          in_car_yn: "Y",
        };
        setNambaList(updatedNambaList);
      } else if (isCarsChecked[index]) {
        updatedNambaList[index] = {
          ...updatedNambaList[index],
          in_car_yn: "N",
        };
        setNambaList(updatedNambaList);
      }
    } else {
      updatedNambaList[index] = {
        ...updatedNambaList[index],
        [name]: value,
        car_yyyymm: yyyymm,
      };
      setNambaList(updatedNambaList);
    }
  };

  if (activeTab === 1) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div className="pc_view">
              <div className="FormHeading_div">
                <h1 className="Form_Heading">팝니다 등록하기</h1>
              </div>
            </div>
            <div className="">
              <div className="FormDetails_Div">
                <div className="FormDiv">
                  <div className="d-flex justify-content-between">
                    <h3 className="FormDetails_Heading">매물정보 등록</h3>
                  </div>
                  <div className="FormDetails_Div">
                    <div className="">
                      <table className="table table-bordered table-responsive table-form">
                        <tbody>
                          <tr>
                            <th style={{ width: "25.92%" }}>매물 종류</th>
                            <td>
                              <div className="d-flex">
                                <Form.Check
                                  inline
                                  label="개인넘버"
                                  name="deal_category_cd"
                                  type="radio"
                                  value={2101}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  onClick={() => setActiveTab(1)}
                                  checked
                                  disabled={
                                    userInfo?.user_type_cd === 202 && true
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="법인넘버"
                                  name="deal_category_cd"
                                  type="radio"
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  onClick={() => setActiveTab(2)}
                                  // disabled={
                                  //   userInfo?.user_type_cd === 201 && true
                                  // }
                                />
                                <Form.Check
                                  inline
                                  label="주선면허"
                                  name="deal_category_cd"
                                  type="radio"
                                  value={2104}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                  onClick={() => setActiveTab(3)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>넘버구분</th>
                            <td>
                              <div className="td_chk3_outer">
                                <Form.Check
                                  inline
                                  label="소형(용달 1.5톤 이하)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2301}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                />
                                <Form.Check
                                  inline
                                  label="중형(1.5톤 초과~16톤 이하)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2302}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                />
                                <Form.Check
                                  inline
                                  label="대형(16톤 초과)"
                                  name="car_weight_cd"
                                  type="radio"
                                  value={2303}
                                  className="CustomCheckbox w-33"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </td>
                          </tr>
                          {parseInt(sellReq.car_weight_cd) === 2301 && (
                            <tr>
                              <th>차량구분</th>
                              <td>
                                <div className="d-flex">
                                  <Form.Check
                                    inline
                                    label="전기차"
                                    name="ecar_yn"
                                    type="radio"
                                    value={`Y`}
                                    className="CustomCheckbox w-33"
                                    onChange={handleInputChange}
                                  />
                                  <Form.Check
                                    inline
                                    label="일반차"
                                    name="ecar_yn"
                                    type="radio"
                                    value={`N`}
                                    className="CustomCheckbox w-33"
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th>톤수입력</th>
                            <td>
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control Table_Input w-120px me-2"
                                  name={"car_weight"}
                                  value={sellReq.car_weight}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>차량연식</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <select
                                  className="form-select Table_Select w-120px  w-sm-50me-10px"
                                  id={"yyyy"}
                                  onChange={handleInputChange}
                                >
                                  <option value="">년</option>
                                  {years.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className="form-select Table_Select w-100px w-sm-50"
                                  id={"mm"}
                                  onChange={handleInputChange}
                                >
                                  <option value="">월</option>
                                  {month.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>판매자 지역</th>
                            <td>
                              <select
                                className="form-select Table_Select w-300px"
                                name={"region_cd"}
                                onChange={handleInputChange}
                              >
                                <option value="">지역을 선택해 주세요</option>
                                {regionCdList.map((region, index) => (
                                  <option key={index} value={region.cd_seq}>
                                    {region.cd_nm}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <th>판매금액</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  placeholder="10만원 단위로 입력"
                                  type="text"
                                  className="form-control Table_Input w-300px me-2"
                                  name={"price"}
                                  value={sellReq.price}
                                  onChange={handleInputChange}
                                />
                                <span className="text-nowrap">만원</span>
                                <div className="CustomCheckerBox ms-4">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleCheckboxChange3}
                                    checked={isCarChecked}
                                  />
                                  <label
                                    className="form-check-label-ml-7 f-medium"
                                    htmlFor="inlineCheckbox7"
                                  >
                                    차량포함
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>자동차등록증(선택)</th>
                            <td>
                              <div className="d-flex align-items-center">
                                <label className="fileBtn" htmlFor="carFile">
                                  파일선택
                                </label>
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  id="carFile"
                                  onChange={handleCarFileSelect}
                                />
                                <div className="txt_attach ms-2">
                                  {renderCarFileInfo()}
                                </div>
                              </div>
                              <R.Btn_Value
                                className="d-lg-inline-block"
                                color="#c1c1c1"
                              >
                                ※ 사진 또는 이미지 파일로 제출해 주세요.
                              </R.Btn_Value>
                              <R.Btn_Value
                                className="d-lg-inline-block"
                                color="#f60"
                              >
                                &nbsp;(관리자 확인용입니다)
                              </R.Btn_Value>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frm_request_wrapper">
              <div className="frm_request_container2">
                <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
                <div className="CustomCheckerBox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={isChecked}
                  />
                  <label
                    className="form-check-label f-medium"
                    htmlFor="inlineCheckbox7"
                  >
                    회원정보와 동일
                  </label>
                </div>
              </div>
              <div className="FormDetails_Div">
                <div className="">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      <tr>
                        <th style={{ width: "25.92%" }}>판매자 이름</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"user_nm"}
                            value={sellReq.user_nm}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>판매자 휴대폰번호</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"phone"}
                            maxLength={11}
                            value={sellReq.phone.replace(/[^0-9]/g, "")}
                            placeholder={"(-)없이 입력"}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>판매자 주소</th>
                        <td>
                          <div className="d-flex flex-column">
                            <div className="daum_search_wrapper">
                              <div className="d-flex w-100 mb-2">
                                <input
                                  type="text"
                                  name={"zip"}
                                  value={sellReq.zip}
                                  onChange={handleInputChange}
                                  className="form-control Table_Input input-zip"
                                  readOnly={true}
                                />
                                <DaumPostcode
                                  className="fileBtn btn btn-primary btn-zip border-1  h-sm-31px"
                                  onCompleteCallback={(data) => {
                                    setSellReq({
                                      ...sellReq,
                                      zip: data.zonecode,
                                      addr1: data.fullAddr,
                                    });
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                name={"addr1"}
                                value={sellReq.addr1}
                                readOnly={true}
                                className="form-control Table_Input ms-0 mb-2"
                              />
                            </div>
                            <input
                              width={"w-100"}
                              type="text"
                              name={"addr2"}
                              value={sellReq.addr2}
                              onChange={handleInputChange}
                              placeholder={"상세 주소 입력"}
                              className="form-control Table_Input"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>화물자동차운송사업허가증(선택)</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="fblFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="fblFile"
                              onChange={handleFblFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderFblFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea
                            className="form-control form-textarea"
                            name={"req_comment"}
                            onChange={handleInputChange}
                            value={sellReq.req_comment}
                            maxLength={200}
                          ></textarea>
                          <div className="textarea_count">
                            {sellReq?.req_comment.length}/200
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="Para_Content">
              ※ 판매자정보는 관리자 확인용입니다.
              {/* ※ 판매자정보는 관리자 확인용이며{" "}
              <span>자동차등록증과 화물자동차운송사업허가증</span>을 반드시
              제출해야 정보 등록이 가능합니다. */}
            </div>
            <div className="d-flex justify-content-center mt-4">
              <CardButton
                width="390px"
                bg="#FF9C00"
                name="등록하기"
                className="btn-main"
                onClick={handleSubmit}
                // disabled={isClicked}
                loading={isClicked}
              ></CardButton>
            </div>
          </div>
        </div>
      </>
    );
  } else if (activeTab === 2) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div className="pc_view">
              <div className="FormHeading_div">
                <h1 className="Form_Heading">팝니다 등록하기</h1>
              </div>
            </div>
            <div className="">
              <h3 className="FormDetails_Heading">매물정보 등록</h3>
              <div className="FormDetails_Div">
                <div className="">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      <tr>
                        <th style={{ width: "25.92%" }}>매물 종류</th>
                        <td>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="개인넘버"
                              name="deal_category_cd"
                              type="radio"
                              value={2101}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              onClick={() => setActiveTab(1)}
                              disabled={userInfo?.user_type_cd === 202 && true}
                            />
                            <Form.Check
                              inline
                              label="법인넘버"
                              type="radio"
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              onClick={() => setActiveTab(2)}
                              // disabled={userInfo?.user_type_cd === 201 && true}
                              checked
                            />
                            <Form.Check
                              inline
                              label="주선면허"
                              name="deal_category_cd"
                              type="radio"
                              value={2104}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              onClick={() => setActiveTab(3)}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>매매/임대</th>
                        <td>
                          <div className="d-flex">
                            <Form.Check
                              inline
                              label="매매"
                              name="deal_category_cd"
                              type="radio"
                              value={2102}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              checked={
                                sellReq.deal_category_cd === "2102" && true
                              }
                            />
                            <Form.Check
                              inline
                              label="임대"
                              name="deal_category_cd"
                              type="radio"
                              value={2103}
                              className="CustomCheckbox w-33"
                              onChange={handleInputChange}
                              checked={
                                sellReq.deal_category_cd === "2103" && true
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      {sellReq.deal_category_cd === "2102" && (
                        <tr>
                          <th>판매 방법</th>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                inline
                                label="일부판매"
                                name="whole_yn"
                                type="radio"
                                value={`N`}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                              />
                              <Form.Check
                                inline
                                label="전체판매"
                                name="whole_yn"
                                type="radio"
                                value={`Y`}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>판매자 지역</th>
                        <td>
                          <select
                            className="form-select Table_Select w-300px"
                            name={"region_cd"}
                            onChange={handleInputChange}
                          >
                            <option value="">지역을 선택해 주세요</option>
                            {regionCdList.map((region, index) => (
                              <option key={index} value={region.cd_seq}>
                                {region.cd_nm}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>판매대수</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="number"
                              id="sellCnt"
                              className="form-control Table_Input w-135px me-2"
                            />{" "}
                            대
                            <button
                              className="btn btn-primary ms-30px"
                              onClick={setCntChange}
                            >
                              넘버등록
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {sellCnt > 0 &&
                    (sellReq.deal_category_cd === "2102" ? (
                      <>
                        {Array.from({ length: sellCnt }, (_, index) => (
                          <table
                            className="table table-bordered table-responsive table-form mt-10px"
                            key={index}
                          >
                            <thead>
                              <tr className="tr_header">
                                <th colSpan={2}>{index + 1}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th style={{ width: "25.92%" }}>넘버종류</th>
                                <td>
                                  <div className="d-flex">
                                    <div className="CustomCheckbox m-0 w-33">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`jigong_flag_${index}`}
                                        id={`inlineRadio${index}_1`}
                                        value="J"
                                        onChange={(e) =>
                                          handleInputChange2(e, index)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`inlineRadio${index}_1`}
                                      >
                                        지입넘버
                                      </label>
                                    </div>
                                    <div className="CustomCheckbox m-0 w-33x">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`jigong_flag_${index}`}
                                        id={`inlineRadio${index}_2`}
                                        value="G"
                                        onChange={(e) =>
                                          handleInputChange2(e, index)
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`inlineRadio${index}_2`}
                                      >
                                        공T/E넘버
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>톤수선택</th>
                                <td>
                                  <div className="d-flex">
                                    <select
                                      className="form-select Table_Select w-300px w-sm-175px"
                                      name={"car_weight_cd"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">
                                        톤수를 선택하세요
                                      </option>
                                      {corpCarCdList.map((corpCarCd, index) => (
                                        <option
                                          key={index}
                                          value={corpCarCd.cd_seq}
                                        >
                                          {corpCarCd.cd_nm}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              {/* {nambaList[index]?.jigong_flag === "J" && ( */}
                              <tr>
                                <th>차량연식</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <select
                                      className="form-select Table_Select w-100px me-10px"
                                      id={`yyyy${index}`}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">년</option>
                                      {years.map((item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                    <select
                                      className="form-select Table_Select w-75px w-sm-65px"
                                      id={`mm${index}`}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">월</option>
                                      {month.map((item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              {/* )} */}
                              <tr>
                                <th>판매금액</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      placeholder="10만원 단위로 입력"
                                      className="form-control Table_Input w-300px "
                                      type="text"
                                      name={"price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.price}
                                    />
                                    <span className="text-nowrap ms-2">
                                      만원
                                    </span>
                                    {/* <R.Table_Input
                                      className="form-control Table_Input w-300px w-sm-175px"
                                      name={"price"}
                                    ></R.Table_Input> */}
                                    {/*<R.Para_Content>*/}
                                    {/*  ※ 금액은 10만원 단위로 입력*/}
                                    {/*</R.Para_Content>*/}

                                    <div className="CustomCheckerBox ms-4">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name={"in_car_yn"}
                                        onChange={(e) =>
                                          handleInputChange2(e, index)
                                        }
                                        checked={isCarsChecked[index]}
                                        // value={nambaList[index]?.in_car_yn}
                                      />
                                      <label
                                        className="form-check-label-ml-7 f-medium"
                                        htmlFor="inlineCheckbox7"
                                      >
                                        차량포함
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {/* {nambaList[index]?.jigong_flag === "J" && ( */}
                              <tr>
                                <th>자동차등록증(선택)</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <label
                                      className="fileBtn"
                                      htmlFor={`carFile${index}`}
                                    >
                                      파일선택
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id={`carFile${index}`}
                                      onChange={(e) =>
                                        handleCarFilesSelect(e, index)
                                      }
                                    />
                                    <div className="txt_attach ms-2">
                                      {renderCarFilesInfo(index)}
                                    </div>
                                  </div>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#c1c1c1"
                                  >
                                    ※ 사진 또는 이미지 파일로 제출해 주세요.
                                  </R.Btn_Value>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#f60"
                                  >
                                    &nbsp;(관리자 확인용입니다)
                                  </R.Btn_Value>
                                </td>
                              </tr>
                              {/* )} */}
                            </tbody>
                          </table>
                        ))}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: sellCnt }, (_, index) => (
                          <table
                            className="table table-bordered table-responsive table-form mt-10px"
                            key={index}
                          >
                            <thead>
                              <tr className="tr_header">
                                <th colSpan={2}>{index + 1}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th style={{ width: "25.92%" }}>톤수선택</th>
                                <td>
                                  <div className="d-flex">
                                    <select
                                      className="form-select Table_Select w-300px w-sm-175px"
                                      name={"car_weight_cd"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">
                                        톤수를 선택하세요
                                      </option>
                                      {corpCarCdList.map((corpCarCd, index) => (
                                        <option
                                          key={index}
                                          value={corpCarCd.cd_seq}
                                        >
                                          {corpCarCd.cd_nm}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>차량연식</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <select
                                      className="form-select Table_Select w-100px me-10px"
                                      id={`yyyy${index}`}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">년</option>
                                      {years.map((item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                    <select
                                      className="form-select Table_Select w-75px w-sm-65px"
                                      id={`mm${index}`}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                    >
                                      <option value="">월</option>
                                      {month.map((item) => (
                                        <option value={item} key={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>임대금액</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      placeholder="10만원 단위로 입력"
                                      className="form-control Table_Input w-300px "
                                      type="text"
                                      name={"price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.price}
                                    />
                                    <span className="text-nowrap ms-2">
                                      만원
                                    </span>
                                    {/* <div className="d-flex align-items-center">
                                    <R.Table_Input
                                      placeholder="10만원 단위로 입력"
                                      className="form-control Table_Input me-10px ms-0 w-sm-175px"
                                      width="180px"
                                      name={"price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.price}
                                    ></R.Table_Input>
                                    <span className="text-nowrap">만원</span> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>지입료</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      placeholder="1만원 단위로 입력"
                                      className="form-control Table_Input w-300px "
                                      type="text"
                                      name={"ji_price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.ji_price}
                                    />
                                    {/* <R.Table_Input
                                      className="form-control Table_Input me-10px ms-0 w-sm-175px"
                                      width="180px"
                                      name={"ji_price"}
                                      onChange={(e) =>
                                        handleInputChange2(e, index)
                                      }
                                      value={nambaList[index]?.ji_price}
                                    ></R.Table_Input> */}
                                    <span className="text-nowrap ms-2">
                                      만원
                                    </span>
                                    {/*<R.Para_Content className="mb-0 ms-2">*/}
                                    {/*  (VAT 별도)*/}
                                    {/*</R.Para_Content>*/}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>자동차등록증(선택)</th>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <label
                                      className="fileBtn"
                                      htmlFor={`carFile${index}`}
                                    >
                                      파일선택
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id={`carFile${index}`}
                                      onChange={(e) =>
                                        handleCarFilesSelect(e, index)
                                      }
                                    />
                                    <div className="txt_attach ms-2">
                                      {renderCarFilesInfo(index)}
                                    </div>
                                  </div>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#c1c1c1"
                                  >
                                    ※ 사진 또는 이미지 파일로 제출해 주세요.
                                  </R.Btn_Value>
                                  <R.Btn_Value
                                    className="d-lg-inline-block"
                                    color="#f60"
                                  >
                                    &nbsp;(관리자 확인용입니다)
                                  </R.Btn_Value>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))}
                      </>
                    ))}
                </div>
              </div>
            </div>

            <div className="frm_request_wrapper">
              <div className="frm_request_container2">
                <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
                <div className="CustomCheckerBox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox7"
                    value="option1"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label f-medium"
                    htmlFor="inlineCheckbox7"
                  >
                    회원정보와 동일
                  </label>
                </div>
              </div>
            </div>
            <div className="FormDetails_Div">
              <table className="table table-bordered table-responsive table-form">
                <tbody>
                  <tr>
                    <th style={{ width: "25.92%" }}>법인명</th>
                    <td>
                      <input
                        type="text"
                        className="form-control Table_Input w-100"
                        name={"corp_nm"}
                        value={sellReq.corp_nm}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>담당자 이름</th>
                    <td>
                      <input
                        type="text"
                        className="form-control Table_Input w-300px"
                        name={"user_nm"}
                        value={sellReq.user_nm}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="text-wrap">담당자 휴대폰번호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control Table_Input w-300px"
                        name={"phone"}
                        maxLength={11}
                        value={sellReq.phone.replace(/[^0-9]/g, "")}
                        placeholder={"(-)없이 입력"}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>법인회사 주소</th>
                    <td>
                      <div className="d-flex flex-column">
                        <div className="daum_search_wrapper">
                          <div className="d-flex w-100 mb-2">
                            <input
                              type="text"
                              name={"zip"}
                              value={sellReq.zip}
                              onChange={handleInputChange}
                              className="form-control Table_Input input-zip"
                              readOnly={true}
                            />
                            <DaumPostcode
                              className="fileBtn btn btn-primary btn-zip border-1  h-sm-31px"
                              onCompleteCallback={(data) => {
                                setSellReq({
                                  ...sellReq,
                                  zip: data.zonecode,
                                  addr1: data.fullAddr,
                                });
                              }}
                            />
                          </div>
                          <input
                            type="text"
                            name={"addr1"}
                            value={sellReq.addr1}
                            readOnly={true}
                            className="form-control Table_Input ms-0 mb-2"
                          />
                        </div>
                        <input
                          type="text"
                          name={"addr2"}
                          value={sellReq.addr2}
                          onChange={handleInputChange}
                          placeholder={"상세 주소 입력"}
                          className="form-control Table_Input"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>사업자등록증(선택)</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <label className="fileBtn" htmlFor="brnoFile">
                          파일선택
                        </label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="brnoFile"
                          onChange={handleBrnoFileSelect}
                        />
                        <div className="txt_attach ms-2">
                          {renderBrnoFileInfo()}
                        </div>
                      </div>
                      <R.Btn_Value
                        className="d-lg-inline-block"
                        color="#c1c1c1"
                      >
                        ※ 사진 또는 이미지 파일로 제출해 주세요
                      </R.Btn_Value>
                    </td>
                  </tr>
                  <tr>
                    <th>화물자동차운송사업허가증(선택)</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <label className="fileBtn" htmlFor="fblFile">
                          파일선택
                        </label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="fblFile"
                          onChange={handleFblFileSelect}
                        />
                        <div className="txt_attach ms-2">
                          {renderFblFileInfo()}
                        </div>
                      </div>
                      <R.Btn_Value
                        className="d-lg-inline-block"
                        color="#c1c1c1"
                      >
                        ※ 사진 또는 이미지 파일로 제출해 주세요
                      </R.Btn_Value>
                    </td>
                  </tr>
                  <tr>
                    <th>요청내용</th>
                    <td>
                      <textarea
                        className="form-control form-textarea"
                        name={"req_comment"}
                        onChange={handleInputChange}
                        value={sellReq.req_comment}
                        maxLength={200}
                      ></textarea>
                      <div className="textarea_count">
                        {sellReq?.req_comment.length}/200
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="Para_Content">
                ※ 판매자정보는 관리자 확인용입니다.
                {/* ※ 판매자정보는 관리자 확인용이며 <span>자동차등록증</span>을
                반드시 제출해야 정보 등록이 가능합니다. */}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <CardButton
                  width="390px"
                  bg="#FF9C00"
                  name="등록하기"
                  className="btn-main"
                  onClick={handleSubmit}
                  loading={isClicked}                  
                ></CardButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (activeTab === 3) {
    return (
      <>
        <div className="py-5">
          <div className="container frm_request_container">
            <div>
              <div className="pc_view">
                <div className="FormHeading_div">
                  <h1 className="Form_Heading">팝니다 등록하기</h1>
                </div>
              </div>
              <div className="">
                <h3 className="FormDetails_Heading">매물정보 등록</h3>
                <div className="FormDetails_Div">
                  <div className="">
                    <table className="table table-bordered table-responsive table-form">
                      <tbody>
                        <tr>
                          <th style={{ width: "25.92%" }}>매물 종류</th>
                          <td>
                            <div className="d-flex">
                              <Form.Check
                                inline
                                label="개인넘버"
                                name="deal_category_cd"
                                type="radio"
                                value={2101}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                onClick={() => setActiveTab(1)}
                                disabled={
                                  userInfo?.user_type_cd === 202 && true
                                }
                              />
                              <Form.Check
                                inline
                                label="법인넘버"
                                name="deal_category_cd"
                                type="radio"
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                onClick={() => setActiveTab(2)}
                                // disabled={
                                //   userInfo?.user_type_cd === 201 && true
                                // }
                              />
                              <Form.Check
                                inline
                                label="주선면허"
                                name="deal_category_cd"
                                type="radio"
                                value={2104}
                                className="CustomCheckbox w-33"
                                onChange={handleInputChange}
                                onClick={() => setActiveTab(3)}
                                checked
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>세금 계산서</th>
                          <td>
                            <div className="d-flex">
                              <div className="CustomCheckbox m-0 w-33">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="tax_paper_yn"
                                  id="inlineRadio4"
                                  value="N"
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio4"
                                >
                                  불가능
                                </label>
                              </div>
                              <div className="CustomCheckbox">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="tax_paper_yn"
                                  id="inlineRadio5"
                                  value="Y"
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio5"
                                >
                                  가능(VAT 별도)
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>판매금액</th>
                          <td>
                            <div className="d-flex align-items-center mb-2">
                              <input
                                className="form-control Table_Input w-300px"
                                width="180px"
                                name={"price"}
                                value={sellReq.price}
                                onChange={handleInputChange}
                              />
                              <span className="text-nowrap ms-2">만원</span>
                            </div>
                            <div className="Para_Content mt-0 mb-2">
                              ※ 금액은 10만원 단위로 입력
                            </div>
                            <div className="td_caption mb-2">
                              <div>
                                마지막 1개월 평균 거래가 : <span>5,260</span>
                                만원
                              </div>
                              <div>
                                판매 금액은 마지막 1개월 평균 거래가 + 500만원 ={" "}
                                <span>5,760</span> 만원 보다 높게 입력할 수
                                없습니다.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>판매자 지역</th>
                          <td>
                            <select
                              className="form-select Table_Select w-300px"
                              name={"region_cd"}
                              onChange={handleInputChange}
                            >
                              <option>지역을 선택하세요</option>
                              {regionCdList.map((region, index) => (
                                <option key={index} value={region.cd_seq}>
                                  {region.cd_nm}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th>거래처 판매</th>
                          <td>
                            <div className="CustomCheckerBox my-10px flex-wrap">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox6"
                                value="option1"
                                checked={isSellChecked}
                                onChange={handleCheckboxChange2}
                              />
                              <label
                                className="form-check-label me-10px"
                                htmlFor="inlineCheckbox6"
                              >
                                팝니다
                              </label>
                              <div className="td_caption">
                                * 기존 거래처 판매를 원하시는분은 체크 후 정보를
                                입력해주세요
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {isSellChecked && (
                      <div className="pc_view">
                        <table className="table table-bordered table-responsive table-form mt-10px">
                          <tbody>
                            <tr>
                              <th style={{ width: "25.92%" }}>거래처 수</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-133px me-2"
                                    name={"cp_cnt"}
                                    value={sellReq?.cp_cnt}
                                    onChange={handleInputChange}
                                  />{" "}
                                  개
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>1년 평균 월 매출</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-300px me-2"
                                    name={"monthly_amount"}
                                    value={sellReq.monthly_amount}
                                    onChange={handleInputChange}
                                  />{" "}
                                  만원
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>퀵 · 화물 매출 비율</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                    퀵
                                    <input
                                      type="text"
                                      className="form-control Table_Input w-100px ms-15px me-10px"
                                      name={"quick_rate"}
                                      value={sellReq.quick_rate}
                                      onChange={handleInputChange}
                                    />
                                    %
                                  </div>
                                  <div className="d-flex align-items-center ms-20px">
                                    화물
                                    <input
                                      type="text"
                                      className="form-control Table_Input w-100px ms-15px me-10px"
                                      name={"freight_rate"}
                                      value={sellReq.freight_rate}
                                      onChange={handleInputChange}
                                    />
                                    %
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>희망 거래가격</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control Table_Input w-300px me-2"
                                    name={"cp_price"}
                                    value={sellReq.cp_price}
                                    onChange={handleInputChange}
                                  />
                                  만원
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="frm_request_wrapper">
                <div className="frm_request_container2">
                  <h3 className="FormDetails_Heading m-0">판매자정보 등록</h3>
                  <div className="CustomCheckerBox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox7"
                      value="option1"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label f-medium"
                      htmlFor="inlineCheckbox7"
                    >
                      회원정보와 동일
                    </label>
                  </div>
                </div>
                <div className="FormDetails_Div">
                  <table className="table table-bordered table-responsive table-form">
                    <tbody>
                      {userInfo?.user_type_cd === 202 && (
                        <tr>
                          <th style={{ width: "25.92%" }}>법인명</th>
                          <td>
                            <input
                              type="text"
                              className="form-control Table_Input w-100"
                              name={"corp_nm"}
                              value={sellReq.corp_nm}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th style={{ width: "25.92%" }}>판매자 이름</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"user_nm"}
                            value={sellReq.user_nm}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-wrap">판매자 휴대폰번호</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            name={"phone"}
                            maxLength={11}
                            value={sellReq.phone.replace(/[^0-9]/g, "")}
                            placeholder={"(-)없이 입력"}
                            onChange={handleInputChange}
                            className="form-control Table_Input w-300px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>판매자 주소</th>
                        <td>
                          <div className="d-flex flex-column">
                            <div className="daum_search_wrapper">
                              <div className="d-flex w-100 mb-2">
                                <input
                                  type="text"
                                  name={"zip"}
                                  value={sellReq.zip}
                                  onChange={handleInputChange}
                                  className="form-control Table_Input input-zip"
                                  readOnly={true}
                                />
                                <DaumPostcode
                                  className="fileBtn btn btn-primary btn-zip border-1 h-sm-31px"
                                  onCompleteCallback={(data) => {
                                    setSellReq({
                                      ...sellReq,
                                      zip: data.zonecode,
                                      addr1: data.fullAddr,
                                    });
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                name={"addr1"}
                                value={sellReq.addr1}
                                readOnly={true}
                                className="form-control Table_Input ms-0 mb-2"
                              />
                            </div>
                            <input
                              width={"w-100"}
                              type="text"
                              name={"addr2"}
                              value={sellReq.addr2}
                              onChange={handleInputChange}
                              placeholder={"상세 주소 입력"}
                              className="form-control Table_Input"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          사업자등록증(선택)
                          {/* {userInfo?.user_type_cd === 201 ? "(선택)" : ""} */}
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="brnoFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="brnoFile"
                              onChange={handleBrnoFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderBrnoFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                      <tr>
                        <th>화물자동차운송 주선사업허가증(선택)</th>
                        <td>
                          <div className="d-flex align-items-center">
                            <label className="fileBtn" htmlFor="fablFile">
                              파일선택
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="fablFile"
                              onChange={handleFablFileSelect}
                            />
                            <div className="txt_attach ms-2">
                              {renderFablFileInfo()}
                            </div>
                          </div>
                          <R.Btn_Value
                            className="d-lg-inline-block"
                            color="#c1c1c1"
                          >
                            ※ 사진 또는 이미지 파일로 제출해 주세요
                          </R.Btn_Value>
                        </td>
                      </tr>
                      <tr>
                        <th>요청내용</th>
                        <td>
                          <textarea
                            className="form-control form-textarea"
                            name={"req_comment"}
                            onChange={handleInputChange}
                            value={sellReq.req_comment}
                            maxLength={200}
                          ></textarea>
                          <div className="textarea_count">
                            {sellReq?.req_comment.length}/200
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="Para_Content">
                    ※ 판매자정보는 관리자 확인용입니다.
                    {/* ※ 판매자정보는 관리자 확인용이며{" "}
                    <span>화물자동차운송 주선사업허가증을</span> 반드시 제출해야
                    정보 등록이 가능합니다. */}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <CardButton
                      width="390px"
                      bg="#FF9C00"
                      name="등록하기"
                      className="btn-main"
                      onClick={handleSubmit}
                      loading={isClicked}
                    ></CardButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SellingFormDetails;
