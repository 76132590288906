import { createBrowserRouter, useRoutes } from "react-router-dom";
import Login from "../pages/login/Login";
import UserSelection from "../pages/SignUp/UserSelection";
import RegularUser from "../pages/SignUp/RegularUser";
import SignUpRegularUser from "../pages/SignUp/SignUpRegularUser";
import Form1CorporationUser from "../pages/SignUp/Form1CorporationUser";
import Form2CorporationUser from "../pages/SignUp/Form2CorporationUser";
import RegularUser2 from "../pages/SignUp/RegularUser2";
import CorporationUser from "../pages/SignUp/CorporationUser";
import FindId from "../pages/FindId/FindId";
import FindPassword from "../pages/FindPassword/FindPassword";
import BuyingTab from "../pages/Selling/personal/buying/BuyingTab";
import SellingTab from "../pages/Selling/personal/selling/SellingTab";
import RequestTab from "../pages/personalSellingRequest/RequestTab";
import RequestCompleteTab from "../pages/personalSellingRequest/Request/RequestCompleteTab";
import CorporateRequestTab from "../pages/corporateSellingRequest/CorporateRequestTab";
import PrivateLayout from "../layouts/privateLayout";
import BrokerageLicenseSellingTab from "../pages/corporateSellingRequest/BrokerageLicenseSellingRequest/BrokerageLicenseSellingTab";
import ErrorPage from "../pages/404";
import MainTab from "../pages/MyPage/MainTab";
import CustomerTab from "../pages/CustomerCenter/CustomerTab";
import ViewPriceTab from "../pages/ViewPrice/ViewTab";
import MainTab2 from "../pages/MyPage/Main/Main2";
import SellingRequest from "../pages/corporateSellingRequest/PersonalBuyingRequest/SellingRequest";
import SellingEditForm from "../pages/corporateSellingRequest/PersonalBuyingRequest/SellingEditForm";
import BuyingRegister from "../pages/corporateSellingRequest/PersonalBuyingRequest/BuyingRegister";
import CSellingRequestTab from "../pages/personalSellingRequest/buying/CSellingRequestTab";
import CSellingReqComplete from "../pages/personalSellingRequest/buying/CSellingRequestComplete/ReqCompleteTab";
import ShowDetail from "../pages/MyPage/Detail/ShowDetail";
import OAuthPage from "../pages/oAuth/OAuthPage";
import NumberOneMain from "../pages/main/Main";
import BuyingEditForm from "../pages/corporateSellingRequest/PersonalBuyingRequest/BuyingEditForm";
import QAEdit from "../pages/CustomerCenter/QAEdit";
import TermsOfUse from "../pages/main/TermsOfUse";
import PrivacyPolicy from "../pages/main/PrivacyPolicy";
import MobildIntro from "../pages/main/MobildIntro";
import Information from "../pages/information/Information";
import Archive from "../pages/archive/Archive";
import ArchiveView from "../pages/archive/ArchiveView";
import ReportForm from "../pages/MyPage/Report/ReportForm";
import ReportComplete from "../pages/MyPage/Report/ReportComplete";
import ReportPdfView from "../pages/MyPage/Report/ReportPdfView";
import QrPreviewPage from "../pages/QrPreview/QrPreviewPage";

import {
  HeaderFixedLayout,
  HeaderFixLayout,
} from "../layouts/headerFixedLayout";

function Router() {
  const routes = [
    {
      path: "/",
      element: <PrivateLayout />,
      children: [
        {
          path: "/",
          element: <NumberOneMain />,
        },
        // {
        //   path: "/intro",
        //   element: <MobildIntro />,
        // },
        
        {
          path: "/terms_of_use",
          element: <TermsOfUse />,
        },
        {
          path: "/privacy_policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/CreateWord",
          element: <OAuthPage />,
        },
        {
          path: "/QrPreviewPage",
          element: <QrPreviewPage />,
        },        
        {
          path: process.env.PUBLIC_URL + "/information",
          element: <Information />,
        },
        {
          path: process.env.PUBLIC_URL + "/archive",
          children: [
            { path: "", index: true, element: <Archive /> },
            { path: ":id/view", element: <ArchiveView /> },
            { path: ":id/edit", element: <ArchiveView /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/selling-edit",
          children: [{ path: "", index: true, element: <SellingEditForm /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/buying-edit",
          children: [{ path: "", index: true, element: <BuyingEditForm /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/qna-edit",
          children: [{ path: "", index: true, element: <QAEdit /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/find-id",
          children: [{ path: "", index: true, element: <FindId /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/find-password",
          children: [{ path: "", index: true, element: <FindPassword /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/selling",
          children: [{ path: "", index: true, element: <SellingTab /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/buying",
          children: [{ path: "", index: true, element: <BuyingTab /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/selling-request1",
          children: [{ path: "", index: true, element: <RequestTab /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/buying-request1",
          children: [
            { path: "", index: true, element: <CSellingRequestTab /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/complete-request",
          children: [
            { path: "", index: true, element: <RequestCompleteTab /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/cselling-complete-request",
          children: [
            { path: "", index: true, element: <CSellingReqComplete /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/corporate-request",
          children: [
            { path: "", index: true, element: <CorporateRequestTab /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/brokerage-request",
          children: [
            { path: "", index: true, element: <BrokerageLicenseSellingTab /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/selling-request",
          children: [{ path: "", index: true, element: <SellingRequest /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/buying-register",
          children: [{ path: "", index: true, element: <BuyingRegister /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/main",
          children: [{ path: "", index: true, element: <MainTab2 /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/main-tab",
          children: [{ path: "", index: true, element: <MainTab /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/main-tab/detail",
          children: [{ path: "", index: true, element: <ShowDetail /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/announcement",
          children: [{ path: "", index: true, element: <CustomerTab /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/view-price",
          children: [{ path: "", index: true, element: <ViewPriceTab /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/report",
          children: [{ path: "", index: true, element: <ReportForm /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/report-complete",
          children: [{ path: "", index: true, element: <ReportComplete /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/report-view",
          children: [{ path: "", index: true, element: <ReportPdfView /> }],
        },
      ],
    },

    {
      element: <HeaderFixedLayout />,
      children: [
        {
          path: process.env.PUBLIC_URL + "/CorporationUser",
          children: [{ path: "", index: true, element: <CorporationUser /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/login",
          children: [{ path: "", index: true, element: <Login /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/signup",
          children: [{ path: "", index: true, element: <UserSelection /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/RegularUser",
          children: [{ path: "", index: true, element: <RegularUser /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/RegularUser2",
          children: [{ path: "", index: true, element: <RegularUser2 /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/signup-regularuser",
          children: [{ path: "", index: true, element: <SignUpRegularUser /> }],
        },
        {
          path: process.env.PUBLIC_URL + "/signup-form1corporationuser",
          children: [
            { path: "", index: true, element: <Form1CorporationUser /> },
          ],
        },
        {
          path: process.env.PUBLIC_URL + "/signup-form2corporationuser",
          children: [
            { path: "", index: true, element: <Form2CorporationUser /> },
          ],
        },
      ],
    },

    {
      path: "*",
      element: <ErrorPage />,
    },
  ];

  return useRoutes(routes);
}

export default Router;
