import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../../../css/Selling/Selling.style";
import PersonalBuying from "./PersonalBuying";
import CorporateBuying from "../../corporate/buying/CorporateBuying";
import BrokerageBuying from "../../brokerage/buying/BrokerageBuying";
import { useAtom } from "jotai";
import { headerText } from "../../../../App";
import { useLocation } from "react-router-dom";

const BuyTabContent = ({ activeTab, subActiveTab, setSubActiveTab }) => {
  if (activeTab === 1) {
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Row>
              <Col md={6} sm={9} className="m-auto">
                <A.SubNavTab>
                  <A.SubNavTab_BtnPink
                    className={subActiveTab === "fourth" ? "active" : ""}
                    onClick={() => setSubActiveTab("fourth")}
                  >
                    전기차 소형
                  </A.SubNavTab_BtnPink>
                  <A.SubNavTab_BtnPink
                    className={subActiveTab === "first" ? "active" : ""}
                    onClick={() => setSubActiveTab("first")}
                  >
                    소형 매물
                  </A.SubNavTab_BtnPink>
                  <A.SubNavTab_BtnPink
                    className={subActiveTab === "second" ? "active" : ""}
                    onClick={() => setSubActiveTab("second")}
                  >
                    중형 매물
                  </A.SubNavTab_BtnPink>
                  <A.SubNavTab_BtnPink
                    className={subActiveTab === "third" ? "active" : ""}
                    onClick={() => setSubActiveTab("third")}
                  >
                    대형 매물
                  </A.SubNavTab_BtnPink>
                </A.SubNavTab>
              </Col>
            </Row>
          </Container>
        </A.Box_Shadow>
        <PersonalBuying activeTab={activeTab} subActiveTab={subActiveTab} />
      </>
    );
  } else if (activeTab === 2) {
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Row>
              <Col md={6} sm={9} className="m-auto">
                <A.SubNavTab position="center">
                  <A.SubNavTab_BtnPink
                    className={subActiveTab === "first" ? "active" : ""}
                    onClick={() => setSubActiveTab("first")}
                  >
                    매매 매물
                  </A.SubNavTab_BtnPink>
                  <A.SubNavTab_BtnPink
                    className={subActiveTab === "second" ? "active" : ""}
                    onClick={() => setSubActiveTab("second")}
                  >
                    임대 매물
                  </A.SubNavTab_BtnPink>
                </A.SubNavTab>
              </Col>
            </Row>
          </Container>
        </A.Box_Shadow>
        <CorporateBuying activeTab={activeTab} subActiveTab={subActiveTab} />
      </>
    );
  } else if (activeTab === 3) {
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Row>
              <Col md={6} sm={9} className="m-auto">
                <A.SubNavTab position="end">
                  <A.SubNavTab_BtnPink_lic
                    className={subActiveTab === "first" ? "active" : ""}
                    onClick={() => setSubActiveTab("first")}
                  >
                    주선면허 매물
                  </A.SubNavTab_BtnPink_lic>
                </A.SubNavTab>
              </Col>
            </Row>
          </Container>
        </A.Box_Shadow>
        <BrokerageBuying activeTab={activeTab} subActiveTab={subActiveTab} />
      </>
    );
  }
  return null;
};

function BuyingTab() {
  const [activeTab, setActiveTab] = useState(1);
  const [subActiveTab, setSubActiveTab] = useState("first");
  const location = useLocation();
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);

  useEffect(() => {
    setHeaderTextAtom("삽니다");
    if (location.state) {
      setActiveTab(location.state.main);
      setSubActiveTab(location.state.sub);
    }
  }, [location]);

  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };
  // const subTabShow = (name) => {
  //     setSubActiveTab(name);
  // };

  return (
    <>
      <A.BuyingTab_Header>
        <Container>
          <A.NavTab_Wrapper>
            <A.NavTab
              className={activeTab === 1 ? "active" : ""}
              onClick={() => tabShow(1)}
            >
              개인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 2 ? "active" : ""}
              onClick={() => tabShow(2)}
            >
              법인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 3 ? "active" : ""}
              onClick={() => tabShow(3)}
            >
              주선면허
            </A.NavTab>
          </A.NavTab_Wrapper>
        </Container>
      </A.BuyingTab_Header>
      <BuyTabContent
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
      />
    </>
  );
}

export default BuyingTab;
