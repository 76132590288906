import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as A from "../../../../css/Selling/Selling.style";
import PersonalSelling from "./PersonalSelling";
import BrokerageSelling from "../../brokerage/selling/BrokerageSelling";
import CorporateSelling from "../../corporate/selling/CorporateSelling";
import { useLocation } from "react-router-dom";
import { headerText } from "../../../../App";
import { useAtom } from "jotai";

const TabContent = ({ activeTab, subActiveTab, setSubActiveTab }) => {
  if (activeTab === 1) {
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Row>
              <Col md={6} sm={9} className="m-auto">
                <A.SubNavTab>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "fourth" ? "active" : ""}
                    onClick={() => setSubActiveTab("fourth")}
                  >
                    전기차 소형
                  </A.SubNavTab_Btn>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "first" ? "active" : ""}
                    onClick={() => setSubActiveTab("first")}
                  >
                    소형 매물
                  </A.SubNavTab_Btn>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "second" ? "active" : ""}
                    onClick={() => setSubActiveTab("second")}
                  >
                    중형 매물
                  </A.SubNavTab_Btn>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "third" ? "active" : ""}
                    onClick={() => setSubActiveTab("third")}
                  >
                    대형 매물
                  </A.SubNavTab_Btn>
                </A.SubNavTab>
              </Col>
            </Row>
          </Container>
        </A.Box_Shadow>
        <PersonalSelling activeTab={activeTab} subActiveTab={subActiveTab} />
      </>
    );
  } else if (activeTab === 2) {
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Row>
              <Col md={6} sm={9} className="m-auto">
                <A.SubNavTab position="center">
                  <A.SubNavTab_Btn
                    className={subActiveTab === "first" ? "active" : ""}
                    onClick={() => setSubActiveTab("first")}
                  >
                    매매 매물
                  </A.SubNavTab_Btn>
                  <A.SubNavTab_Btn
                    className={subActiveTab === "second" ? "active" : ""}
                    onClick={() => setSubActiveTab("second")}
                  >
                    임대 매물
                  </A.SubNavTab_Btn>
                </A.SubNavTab>
              </Col>
            </Row>
          </Container>
        </A.Box_Shadow>
        <CorporateSelling activeTab={activeTab} subActiveTab={subActiveTab} />
      </>
    );
  } else if (activeTab === 3) {
    return (
      <>
        <A.Box_Shadow>
          <Container>
            <Col md={6} sm={9} className="m-auto">
              <A.SubNavTab position="end">
                <A.SubNavTab_Btn_lic
                  className={subActiveTab === "first" ? "active" : ""}
                  onClick={() => setSubActiveTab("first")}
                >
                  주선면허 매물
                </A.SubNavTab_Btn_lic>
              </A.SubNavTab>
            </Col>
          </Container>
        </A.Box_Shadow>
        <BrokerageSelling activeTab={activeTab} subActiveTab={subActiveTab} />
      </>
    );
  }
  return null;
};
function SellingTab() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [subActiveTab, setSubActiveTab] = useState("first");
  const [headerTextAtom, setHeaderTextAtom] = useAtom(headerText);

  useEffect(() => {
    setHeaderTextAtom("팝니다");
    if (location.state) {
      setActiveTab(location.state.main);
      setSubActiveTab(location.state.sub);
    }
  }, [location]);

  const tabShow = (id) => {
    setActiveTab(id);
    setSubActiveTab("first"); // Reset subActiveTab when changing the main tab
  };

  return (
    <>
      <A.SellingTab_Header>
        <Container>
          <A.NavTab_Wrapper>
            <A.NavTab
              className={activeTab === 1 ? "active" : ""}
              onClick={() => tabShow(1)}
            >
              개인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 2 ? "active" : ""}
              onClick={() => tabShow(2)}
            >
              법인넘버
            </A.NavTab>
            <A.NavTab
              className={activeTab === 3 ? "active" : ""}
              onClick={() => tabShow(3)}
            >
              주선면허
            </A.NavTab>
          </A.NavTab_Wrapper>
        </Container>
      </A.SellingTab_Header>
      <TabContent
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        setSubActiveTab={setSubActiveTab}
      />
    </>
  );
}

export default SellingTab;
